import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

// Define a type for the slice state
interface MenuState {
  isMenuOpen: boolean;
  isLogoDescription:boolean;
  scrollPosition:string;
  activeSection:string;
  isGalleryWhyUs:boolean;
  isGalleryFactorX:boolean;
  activeTextBottom:string;
  activeTextBottomHover:string;
  activeLanguage:string;
}

// Define the initial state using that type
const initialState: MenuState = {
  isMenuOpen: false,
  isLogoDescription:false,
  scrollPosition:'home',
  activeSection:'home',
  activeTextBottom:'',
  activeTextBottomHover:'',
  isGalleryWhyUs:false,
  isGalleryFactorX:false,
  activeLanguage:'en'
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleOpenMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    closeMenu : (state) => {
      state.isMenuOpen = false;
    },
    hiddenLogoDescription: (state) => {
      state.isLogoDescription = false;
    },
    visibleLogoDescription: (state) => {
      state.isLogoDescription = true;
    },
    setScrollPosition: (state, action: PayloadAction<string>) => {
      state.scrollPosition = action.payload;
    },
    setActiveSection: (state, action: PayloadAction<string>) => {
      state.activeSection = action.payload;
    },
    toggleIsGalleryWhyUs:(state) =>{
      state.isGalleryWhyUs = !state.isGalleryWhyUs;
    },
    toggleIsGalleryFactorX:(state) =>{
      state.isGalleryFactorX = !state.isGalleryFactorX;
    },
    closeIsGalleryWhyUs:(state) =>{
      state.isGalleryWhyUs = false;
    },
    closeIsGalleryFactorX:(state) =>{
      state.isGalleryFactorX = false;
    },
    setActiveTextBottom:(state, action: PayloadAction<string>)=>{
      state.activeTextBottom = action.payload;
    },
    setActiveTextBottomHover:(state, action: PayloadAction<string>)=>{
      state.activeTextBottomHover = action.payload;
    },
    setActiveLanguage:(state,action:PayloadAction<string>)=>{
      state.activeLanguage = action.payload;
    }
  },
});

export const { toggleOpenMenu,hiddenLogoDescription,visibleLogoDescription,setScrollPosition,setActiveSection,toggleIsGalleryWhyUs,toggleIsGalleryFactorX,closeIsGalleryWhyUs,closeIsGalleryFactorX,setActiveTextBottom,setActiveTextBottomHover,setActiveLanguage,closeMenu } = menuSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectMenuIsOpen = (state: RootState) => state.menu.isMenuOpen;
// export const selectIsLogoDescription = (state: RootState) => state.menu.isLogoDescription;
// export const selectScrollPosition= (state: RootState) => state.menu.scrollPosition;
// export const selectActiveSection= (state: RootState) => state.menu.activeSection;

export default menuSlice.reducer;
